






















































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  AttendanceType,
  OccupationalGroup,
  RegistrationResponse,
} from "./@types";
import RegistrationStatBlock from "./RegistrationStatBlock.vue";
import RegistrationService, {
  SearchFilter,
  SearchRegistrationsQueryParams,
} from "./services/RegistrationService";

interface AllStatsSideEvents {
  memberFestival: number;
  roadshow: number;
  technicalTraining: number;
  curiaConsultationHour: number;
  occupationalGroupGathering: number;
}

const initialStats = () => ({
  allMembers: 0,
  inPerson: 0,
  preVote: 0,
  digital: 0,
  repInPerson: 0,
  repDigital: 0,
  sideEvents: {
    memberFestival: 0,
    roadshow: 0,
    technicalTraining: 0,
    curiaConsultationHour: 0,
    occupationalGroupGathering: 0,
  },
});

@Component({
  components: {
    RegistrationStatBlock,
  },
})
export default class RegistrationStats extends Vue {
  allStatsLoading = true;
  composerStatsLoading = true;
  lyricistStatsLoading = true;
  publisherStatsLoading = true;

  allStats = initialStats();
  composerStats = initialStats();
  lyricistStats = initialStats();
  publisherStats = initialStats();

  defaultSearchArgs: SearchRegistrationsQueryParams = {
    searchQuery: "",
    filters: [],
    resultsPerPage: 1, // Cannot be less than 1
  };

  inPersonArgs: SearchRegistrationsQueryParams = {
    ...this.defaultSearchArgs,
    filters: [
      {
        key: "attendanceType",
        value: AttendanceType.IN_PERSON,
      },
    ],
  };

  prevoteArgs: SearchRegistrationsQueryParams = {
    ...this.defaultSearchArgs,
    filters: [
      {
        key: "attendanceType",
        value: AttendanceType.PRE_VOTE,
      },
    ],
  };

  digitalArgs: SearchRegistrationsQueryParams = {
    ...this.defaultSearchArgs,
    filters: [
      {
        key: "attendanceType",
        value: AttendanceType.DIGITAL,
      },
    ],
  };

  representedInPersonArgs: SearchRegistrationsQueryParams = {
    ...this.defaultSearchArgs,
    filters: [
      {
        key: "attendanceType",
        value: AttendanceType.BY_REPRESENTATIVE_IN_PERSON,
      },
    ],
  };

  representedDigitalArgs: SearchRegistrationsQueryParams = {
    ...this.defaultSearchArgs,
    filters: [
      {
        key: "attendanceType",
        value: AttendanceType.BY_REPRESENTATIVE_DIGITAL,
      },
    ],
  };

  sideEventArgs: SearchRegistrationsQueryParams = {
    ...this.defaultSearchArgs,
    filters: [
      {
        key: "attendanceType",
        value: AttendanceType.BY_REPRESENTATIVE_DIGITAL,
      },
    ],
  };

  async created() {
    // Staggering requests
    await this.getAllStats();
    await this.getLyricistStats();
    await this.getComposerStats();
    await this.getPublisherStats();
  }

  async getAllStats() {
    const [
      sideEventStats,
      allMembers,
      inPerson,
      preVote,
      digital,
      repInPerson,
      repDigital,
    ] = await Promise.all([
      this.getAllSideEventsPromise(),
      // All Members
      RegistrationService.searchRegistrations<RegistrationResponse>(
        this.defaultSearchArgs
      ).then((data) => data.totalCount),
      // Present / In Person Attendance
      RegistrationService.searchRegistrations<RegistrationResponse>(
        this.inPersonArgs
      ).then((data) => data.totalCount),
      // Pre-Vote
      RegistrationService.searchRegistrations<RegistrationResponse>(
        this.prevoteArgs
      ).then((data) => data.totalCount),
      // Digital Attendance
      RegistrationService.searchRegistrations<RegistrationResponse>(
        this.digitalArgs
      ).then((data) => data.totalCount),
      // Represented - In Person
      RegistrationService.searchRegistrations<RegistrationResponse>(
        this.representedInPersonArgs
      ).then((data) => data.totalCount),
      // Represented - In Digital
      RegistrationService.searchRegistrations<RegistrationResponse>(
        this.representedDigitalArgs
      ).then((data) => data.totalCount),
    ]);

    this.allStats = {
      ...this.allStats,
      allMembers,
      inPerson,
      preVote,
      digital,
      repInPerson,
      repDigital,
      sideEvents: sideEventStats,
    };

    this.allStatsLoading = false;
  }

  async getComposerStats() {
    const groupFilter = {
      key: "member.`group`",
      value: OccupationalGroup.K,
    };

    const [
      sideEventStats,
      allMembers,
      inPerson,
      preVote,
      digital,
      repInPerson,
      repDigital,
    ] = await Promise.all([
      this.getAllSideEventsPromise(groupFilter),
      // All Members
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.defaultSearchArgs,
        filters: [...this.defaultSearchArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Present / In Person Attendance
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.inPersonArgs,
        filters: [...this.inPersonArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Pre-Vote
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.prevoteArgs,
        filters: [...this.prevoteArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Digital Attendance
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.digitalArgs,
        filters: [...this.digitalArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Represented - In Person
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.representedInPersonArgs,
        filters: [...this.representedInPersonArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Represented - In Digital
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.representedDigitalArgs,
        filters: [...this.representedDigitalArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
    ]);

    this.composerStats = {
      ...this.composerStats,
      allMembers,
      inPerson,
      preVote,
      digital,
      repInPerson,
      repDigital,
      sideEvents: sideEventStats,
    };

    this.composerStatsLoading = false;
  }

  async getLyricistStats() {
    const groupFilter = {
      key: "member.`group`",
      value: OccupationalGroup.T,
    };

    const [
      sideEventStats,
      allMembers,
      inPerson,
      preVote,
      digital,
      repInPerson,
      repDigital,
    ] = await Promise.all([
      this.getAllSideEventsPromise(groupFilter),
      // All Members
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.defaultSearchArgs,
        filters: [...this.defaultSearchArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Present / In Person Attendance
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.inPersonArgs,
        filters: [...this.inPersonArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Pre-Vote
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.prevoteArgs,
        filters: [...this.prevoteArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Digital Attendance
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.digitalArgs,
        filters: [...this.digitalArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Represented - In Person
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.representedInPersonArgs,
        filters: [...this.representedInPersonArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Represented - In Digital
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.representedDigitalArgs,
        filters: [...this.representedDigitalArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
    ]);

    this.lyricistStats = {
      ...this.lyricistStats,
      allMembers,
      inPerson,
      preVote,
      digital,
      repInPerson,
      repDigital,
      sideEvents: sideEventStats,
    };

    this.lyricistStatsLoading = false;
  }

  async getPublisherStats() {
    const groupFilter = {
      key: "member.`group`",
      value: OccupationalGroup.V,
    };

    const [
      sideEventStats,
      allMembers,
      inPerson,
      preVote,
      digital,
      repInPerson,
      repDigital,
    ] = await Promise.all([
      this.getAllSideEventsPromise(groupFilter),
      // All Members
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.defaultSearchArgs,
        filters: [...this.defaultSearchArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Present / In Person Attendance
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.inPersonArgs,
        filters: [...this.inPersonArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Pre-Vote
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.prevoteArgs,
        filters: [...this.prevoteArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Digital Attendance
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.digitalArgs,
        filters: [...this.digitalArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Represented - In Person
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.representedInPersonArgs,
        filters: [...this.representedInPersonArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
      // Represented - In Digital
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.representedDigitalArgs,
        filters: [...this.representedDigitalArgs.filters, groupFilter],
      }).then((data) => data.totalCount),
    ]);

    this.publisherStats = {
      ...this.publisherStats,
      allMembers,
      inPerson,
      preVote,
      digital,
      repInPerson,
      repDigital,
      sideEvents: sideEventStats,
    };

    this.publisherStatsLoading = false;
  }

  async getAllSideEventsPromise(
    groupFilter?: SearchFilter
  ): Promise<AllStatsSideEvents> {
    return Promise.all([
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.defaultSearchArgs,
        filters: [
          {
            key: "member.sideEventAttendance.memberFestival",
            value: "TRUE",
            fieldType: "BOOLEAN",
          },
          groupFilter,
        ].filter((v) => v) as SearchFilter[],
      }).then((data) => data.totalCount),
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.defaultSearchArgs,
        filters: [
          {
            key: "member.sideEventAttendance.roadshow",
            value: "TRUE",
            fieldType: "BOOLEAN",
          },
          groupFilter,
        ].filter((v) => v) as SearchFilter[],
      }).then((data) => data.totalCount),
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.defaultSearchArgs,
        filters: [
          {
            key: "member.sideEventAttendance.technicalTraining",
            value: "TRUE",
            fieldType: "BOOLEAN",
          },
          groupFilter,
        ].filter((v) => v) as SearchFilter[],
      }).then((data) => data.totalCount),
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.defaultSearchArgs,
        filters: [
          {
            key: "member.sideEventAttendance.curiaConsultationHour",
            value: "TRUE",
            fieldType: "BOOLEAN",
          },
          groupFilter,
        ].filter((v) => v) as SearchFilter[],
      }).then((data) => data.totalCount),
      RegistrationService.searchRegistrations<RegistrationResponse>({
        ...this.defaultSearchArgs,
        filters: [
          {
            key: "member.sideEventAttendance.occupationalGroupGathering",
            value: "TRUE",
            fieldType: "BOOLEAN",
          },
          groupFilter,
        ].filter((v) => v) as SearchFilter[],
      }).then((data) => data.totalCount),
    ]).then(
      ([
        memberFestival,
        roadshow,
        technicalTraining,
        curiaConsultationHour,
        occupationalGroupGathering,
      ]) => {
        return {
          memberFestival,
          roadshow,
          technicalTraining,
          curiaConsultationHour,
          occupationalGroupGathering,
        };
      }
    );
  }
}
