

























import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class RegistrationStatBlock extends Vue {
  @Prop({ required: true }) value!: number;
  @Prop({ required: true }) text!: string;
  @Prop({ default: false }) loading!: boolean;
}
