






import PageTemplate from "@/components/PageTemplate.vue";
import RegistrationStats from "@/components/Registrations/RegistrationStats.vue";

import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    RegistrationStats,
  },
})
export default class RegistrationStatsView extends Vue {
  RouteName = RouteName;
}
